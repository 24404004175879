<template>
  <!-- 多规格名 页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 名称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="名称">
                    <a-input v-model="queryParam.name" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <!-- 状态 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="状态">
                    <a-select v-model="queryParam.delFlag" allow-clear placeholder="请选择">
                      <a-select-option :value="1">启用</a-select-option>
                      <a-select-option :value="3">禁用</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                </a-col>
                <!-- 新增按钮 -->
                <a-col :md="24" :sm="24">
                  <a-button type="primary" style="border-radius: 4px; margin-bottom: 20px" @click="$refs.addForm.add()"
                    >新增</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <!-- 状态插槽 -->
            <span slot="delFlag" slot-scope="text, record" @click="clickSwitch(record)">
              <a-switch
                checked-children="启用"
                un-checked-children="禁用"
                v-model="record.checked"
                @change="changeSwitch"
              />
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.editForm.edit(record)">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysItemDelete(record)">
                <a>删除</a>
              </a-popconfirm>
            </span>
          </a-table>
        </div>
      </a-col>
    </a-row>
    <!-- 分页组件 -->
    <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
      <a-pagination
        :pageSize="page.pageSize"
        :current="page.pageNo"
        show-size-changer
        :page-size-options="['10', '20', '50', '100']"
        show-quick-jumper
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="pageChange"
        @showSizeChange="sizeChange"
      ></a-pagination>
    </div>
    <!-- 引入子组件 -->
    <add-form @ok="initTableData" ref="addForm"></add-form>
    <edit-form @ok="getList" ref="editForm"></edit-form>
  </div>
</template>

<script>
import { specNamePageList, specNameList, specNameDelete } from '@/api/modular/mallLiving/specName'

import addForm from './addForm.vue'
import editForm from './editForm.vue'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '规格名',
    align: 'center',
    dataIndex: 'name',
  },
  {
    title: '状态',
    dataIndex: 'delFlag',
    align: 'center',
    scopedSlots: { customRender: 'delFlag' },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
]
export default {
  data() {
    return {
      columns,
      data: [],
      recordItem: null,
      total: 0,
      loading: false,
      queryParam: {},
      page: {
        pageNo: 2,
        pageSize: 10,
      },
    }
  },
  components: {
    addForm,
    editForm,
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      specNamePageList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 删除
    sysItemDelete(record) {
      this.recordItem = record
      this.disableCommodityCate(2) //2:删除
    },
    clickSwitch(record) {
      this.recordItem = record
    },
    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableCommodityCate(delFlag)
      }, 300)
    },
    async disableCommodityCate(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await specNameDelete(params)
      var text = delFlag == 1 ? '启用' : delFlag == 2 ? '删除' : '禁用'
      if (data.code == 200) {
        this.$message.success(this.recordItem.name + ' 已' + text)
        this.getList()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 35px;
  height: 35px;
}
</style>
